<template>
  <div>
    <div class="card-body">
      <!--begin::Row-->
      <!--begin::Row-->
      <div class="row">
        <label class="col-3"></label>
        <div class="col-9">
          <h6 class="text-dark font-weight-bold mb-10">{{ $t('label.change_your_password') }}:</h6>
        </div>
      </div>
      <!--end::Row-->
      <!--begin::Group-->
      <b-row>
        <b-col md="3"></b-col>
        <b-col md="6">
          <app-input
              :label="$t('label.current_password')"
              v-model="form.currentPassword"
              :placeholder="$t('placeholder.enter_current_password')"
              required
              password
              :with-error="error.currentPassword"
              :help-message="$t('description.min_length_8')"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3"></b-col>
        <b-col md="6">
          <app-input
              :label="$t('label.new_password')"
              v-model="form.newPassword"
              :placeholder="$t('placeholder.enter_new_password')"
              password
              required
              :with-error="error.newPassword"
              :help-message="$t('description.min_length_8')"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3"></b-col>
        <b-col md="6">
          <app-input
              :label="$t('label.verify_password')"
              v-model="form.verifyPassword"
              :placeholder="$t('placeholder.verify_password')"
              password
              required
              :with-error="error.verifyPassword"
              :help-message="$t('description.min_length_8')"
          />
        </b-col>
      </b-row>
      <!--end::Group-->
    </div>

    <portal to="subheader-toolbar">
      <b-button variant="success" @click="changePassword">
        {{ $t('btn.save_changes') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppInput from '@/components/form-groups/AppInput';

export default {
  components: {
    AppInput
  },
  data: () => ({
    form: {
      currentPassword: '',
      newPassword: '',
      verifyPassword: ''
    },
    error: {
      currentPassword: false,
      newPassword: false,
      verifyPassword: false,
    }
  }),
  methods: {
    changePassword() {
      if (!this.validatePasswords()) return false;
      this.submit();
    },
    validatePasswords() {
      this.error.currentPassword = !this.form.currentPassword.length;
      this.error.newPassword = this.form.newPassword.length < 8 || this.form.newPassword !== this.form.verifyPassword;
      this.error.verifyPassword = this.form.verifyPassword.length < 8 || this.form.newPassword !== this.form.verifyPassword;

      return !Object.values(this.error).includes(true);
    },
    clearForm() {
      this.form.currentPassword = '';
      this.form.newPassword = '';
      this.form.verifyPassword = '';
    },
    submit() {
      this.$store.dispatch('profileStore/UPDATE_PASSWORD', {
        oldPassword: this.form.currentPassword, newPassword: this.form.newPassword
      }).then(() => {
        this.clearForm();
      })
    }
  }
}
</script>